$(function () {

    $('.expandable-text').each(function() {
        var expandableText = $(this);
        var height = expandableText.height();
        var collapsedHeight = '14em';
        expandableText.css({height: collapsedHeight});
        if (expandableText.height() > height) {
            expandableText.css({
                height: height + 'px'
            });
        } else {
            $(this).after('<p class="view-more">[...] &nbsp;<a href="#">mehr sehen</a></p>');
            $(this).after('<p class="view-less"><a href="#">weniger sehen</a></p>');
            var viewMoreLink = $(this).parent().find('.view-more a');
            var viewLessLink = $(this).parent().find('.view-less a');
            viewMoreLink.click(function() {
                viewMoreLink.parent().hide();
                expandableText.css({height: height + 'px'});
                viewLessLink.parent().show();
                $('#maincontent').effect('highlight', {color: '#bfe6f7'}, 700);
                return false;
            });
            viewLessLink.click(function() {
                viewLessLink.parent().hide();
                expandableText.css({height: collapsedHeight});
                viewMoreLink.parent().show();
                $('html, body').animate({scrollTop:0}, 'slow');
                return false;
            });
            viewLessLink.parent().hide();
            expandableText.css({height: collapsedHeight});
            viewMoreLink.parent().show();		}
    });

    var akkordeon = $('.akkordeon');

    akkordeon.each(function() {
        var akkordeonContent = $(this).find('.akkordeon-content');
        var akkordeonHeader = $(this).find('.akkordeon-header');

        akkordeonContent.hide();
        $(this).hover(function() {
            $(this).addClass('akkordeon_hover');
        }, function() {
            $(this).removeClass('akkordeon_hover');
        });
        akkordeonHeader.click(function() {
            akkordeonContent.toggle();
            akkordeonHeader.parent().toggleClass('akkordeon_toggled').removeClass('akkordeon_hover');
        });
    });

});