/*global $:false */
/*global window:false */
$(function ($) {
    'use strict';

    $('html').removeClass('no-js').addClass('js');
    // Auswählen eines anderen Tabs in der mobilen Navigation (<select>) ist wie das Anklicken des Tabs
    $('.fx-programm-nav-small').change(function () {
        window.location.href = $('.fx-tabs-navigation a.' + $(this).val()).attr('href');
    });

    $('.dropdown-toggle').dropdown();
});
