$(function(){
    
    $('.fx-zoomable').fancybox({
        helpers : {
            title: {
                type: 'inside'
            }
        }
    });
    
});